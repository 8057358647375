import "./index.css";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid className="PrivacyContainer">
      <div class="row">
        <div class="col-md-12">
          <h3>PRIVACY POLICY</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>CONDITIONS OF USE</h3>
          <p>
            This privacy policy explains how Blink uses and protects any
            information that you provide when using the app and website. We take
            the privacy of our members VERY seriously! For our consumers: You
            will need to provide an email address, first name for verification
            to effectively authenticate your identity in order to begin using
            our soltution. Then user has to go though a sign up process. There
            are various steps in sign up process that are mandatory for user to
            provide in order to set up his company which include information for
            his sites,location,mandatory fields,custom fields and other features
            that user want to enable against his company. If user has optioned
            out of departments and suppliers then user must create
            suppliers,departments before entering assets against his company.
            Blink is committed to ensuring that your privacy is protected.
            Should we ask you to provide certain information by which you can be
            identified when using this website or the app, you can be assured
            that it will only be used in accordance to this privacy statement.
            Blink may change this policy by updating this page. You should check
            this page periodically to ensure that you are informed and with any
            changes which may concern you. This policy is effective from April
            5, 2020 .
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>WHAT WE COLLECT:</h3>
          <p>
            To provide with you the with the ability to add company assets
            against your valuable company assets we collection location and site
            data to link with assets and information like departments. When
            importing data all this information will be available in excel sheet
            by user. Import feature has strict rules and the location name,site
            names and depart names are case sensitive. End Users and Consumers:
            User name or first name. Email address used for verification and
            authentication of users.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>PRIVACY</h3>
          <p>
            Please review our Privacy Policy, which also governs your visit to
            our website, the use of our apps and to understand our practices.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>SECURITY</h3>
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic, and managerial procedures to
            safeguard and secure the information we collect online.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>CONTROLLING YOUR PERSONAL INFORMATION</h3>
          <p>
            We will not sell, distribute, or lease your personal information to
            third parties unless we have your explicit permission or are
            required by law to do so.
          </p>
        </div>
      </div>
    </Container>
  );
};
export default PrivacyPolicy;
