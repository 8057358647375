import React from "react";
import "./index.css";
import FACEBOOK_ICON from "../../assets/images/footer/facebook.svg";
import LINKEDIN_ICON from "../../assets/images/footer/linkedIn.svg";
import INSTAGRAM_ICON from "../../assets/images/footer/instagram.svg";
import TWITTER_ICON from "../../assets/images/footer/twitter.svg";
import PLAYSTORE_ICON from "../../assets/images/footer/apple store.png";
import APPLE_STORE from "../../assets/images/footer/icon_google_play.png";
import { EnglishKeys } from "../../language/EN";
import { UrduKeys } from "../../language/UR";
import { useHistory } from "react-router-dom";

const Footer = ({ activeLanguage }) => {
  const history = useHistory();
  const footerIcons = [
    FACEBOOK_ICON,
    LINKEDIN_ICON,
    INSTAGRAM_ICON,
    TWITTER_ICON,
  ];
  const { hrefs: englishHrefs } = EnglishKeys.footer_uper_portion;
  const { hrefs: urduHrefs } = UrduKeys.footer_uper_portion;
  const activeHrefs = activeLanguage === 1 ? englishHrefs : urduHrefs;
  const fontFamily = activeLanguage === 2 ? "urdu-regular" : "";
  return (
    <div className="footer_container">
      <div
        style={{ textAlign: activeLanguage === 2 ? "right" : "" }}
        className="upper_portion"
      >
        <h3 style={{ fontFamily }}>
          {activeLanguage === 2
            ? UrduKeys.footer_uper_portion.h3
            : EnglishKeys.footer_uper_portion.h3}
        </h3>
        <div
          style={{ flexDirection: activeLanguage === 2 ? "row-reverse" : "" }}
          className="upper_portion_icons"
        >
          <img
            width="150"
            height="45"
            src={APPLE_STORE}
            onClick={() => {
              const url =
                "https://play.google.com/store/apps/details?id=apps.blink.user";
              window.open(url, "_blank");
            }}
          />
          <img width="150" height="60" src={PLAYSTORE_ICON} />
        </div>
        <div
          style={{
            textAlign: activeLanguage === 2 ? "right" : "",
          }}
          className={activeLanguage === 1 ? "hrefs_" : "hrefs_ urduFonts"}
        >
          <div>
            <p>{activeHrefs[0]}</p>
            <p>{activeHrefs[1]}</p>
            <p>{activeHrefs[2]}</p>
          </div>
          <div>
            <p>{activeHrefs[3]}</p>
            <p>{activeHrefs[4]}</p>
            <p>{activeHrefs[5]}</p>
          </div>
          <div>
            <p onClick={() => history.push("/terms")}>{activeHrefs[6]}</p>
            <p onClick={() => history.push("/privacy")}>{activeHrefs[7]}</p>
            <p>{activeHrefs[8]}</p>
          </div>
        </div>
      </div>
      <div className="lower_portion">
        <div
          style={{ flexDirection: activeLanguage === 2 ? "row-reverse" : "" }}
        >
          <span style={{ fontFamily }}>
            {activeLanguage === 1
              ? EnglishKeys.lower_portion.span
              : UrduKeys.lower_portion.span}
          </span>
          {footerIcons.map((icon) => (
            <img src={icon} className="img-fluid" />
          ))}
        </div>
        <div>
          <p style={{ fontFamily }}>
            {activeLanguage === 1
              ? EnglishKeys.lower_portion.p
              : UrduKeys.lower_portion.p}
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
