import React from 'react';
import './index.css';
import DRIVE_WITH_BLINK_LOGO from '../../assets/images/img_drive_with_blink.png';
import { EnglishKeys } from '../../language/EN';
import { UrduKeys } from '../../language/UR';
import { Fade } from 'react-awesome-reveal';
import useWindowDimensions from '../../global/dimensions/dimensionhook';
export const DriveWithBlink = ({ activeLanguage }) => {
	let fontFamily = activeLanguage === 2 ? 'urdu-regular' : '';
	return (
		<div
			style={{
				flexDirection: activeLanguage === 2 ? 'row-reverse' : '',
				textAlign: activeLanguage === 2 ? 'right' : '',
			}}
			className="dwb_container"
		>
			<div style={{ alignItems: activeLanguage === 2 ? 'flex-end' : '' }} className="dwb_text_btn">
				<Fade in={true} direction={'left'}>
					<h3 style={{ fontFamily }}>
						{activeLanguage === 1 ? EnglishKeys.dwb_text_btn.h3 : UrduKeys.dwb_text_btn.h3}
					</h3>
					<p style={{ fontFamily }}>
						{activeLanguage === 1 ? EnglishKeys.dwb_text_btn.p : UrduKeys.dwb_text_btn.p}
					</p>
					<button
						style={{ fontFamily }}
					>
						{activeLanguage === 1 ? EnglishKeys.dwb_text_btn.btnText : UrduKeys.dwb_text_btn.btnText}
					</button>
				</Fade>
			</div>

			<img src={DRIVE_WITH_BLINK_LOGO} />
		</div>
	);
};
