import React from 'react';

import './index.css';
import FLEXIBILTY_LOGO from '../../assets/images/img_complete_flexibility.png';
import PRICING_LOGO from '../../assets/images/img_transparet_pricing.png';
import MOBILITY_LOGO from '../../assets/images/img_marketplace_mobility.png';
import { Slide, Fade, Zoom } from 'react-awesome-reveal';
import { EnglishKeys } from '../../language/EN';
import { UrduKeys } from '../../language/UR';
import useWindowDimensions from '../../global/dimensions/dimensionhook';

const ServicesDescriptions = ({ activeLanguage }) => {
	const { height, width } = useWindowDimensions();
	return (
		<div className="services_container">
			<div className="desc_1">
				<img src={FLEXIBILTY_LOGO} />

				<div style={{ textAlign: activeLanguage === 2 ? 'right' : '' }} className="desc_1_text">
					<Fade in={true} direction="left">
						<h3 style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
							{activeLanguage === 1 ? EnglishKeys.desc_1_text.h3 : UrduKeys.desc_1_text.h3}
						</h3>
						<p style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
							{activeLanguage === 1 ? EnglishKeys.desc_1_text.p : UrduKeys.desc_1_text.p}
						</p>
					</Fade>
				</div>
			</div>
			<div className="desc_2">
				<div style={{ textAlign: activeLanguage === 2 ? 'right' : '' }} className="img_and_text_container">
					<div className="img_">
						<img src={MOBILITY_LOGO} />
					</div>

					<div className="desc_2_text_">
						<Fade in={true} direction={'left'}>
							<h3 style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
								{activeLanguage === 1 ? EnglishKeys.desc_2_text_1.h3 : UrduKeys.desc_2_text_1.h3}
							</h3>
							<p style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
								{activeLanguage === 1 ? EnglishKeys.desc_2_text_1.p : UrduKeys.desc_2_text_1.p}
							</p>
						</Fade>
					</div>
				</div>
			</div>
			<div style={{ textAlign: activeLanguage === 2 ? 'right' : '' }} className="img_and_text_container">
				<div className="img_" style={{ order: 1 }}>
					<img src={PRICING_LOGO} />
				</div>
				<div className="desc_2_text_" style={{ order: width > 1000 ? 0 : 1 }}>
					<Fade in={true} direction={'right'}>
						<h3 style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
							{activeLanguage === 1 ? EnglishKeys.desc_2_text_2.h3 : UrduKeys.desc_2_text_2.h3}
						</h3>
						<p style={{ fontFamily: activeLanguage === 2 ? 'urdu-regular' : '' }}>
							{activeLanguage === 1 ? EnglishKeys.desc_2_text_2.p : UrduKeys.desc_2_text_2.p}
						</p>
					</Fade>
				</div>
			</div>
		</div>
	);
};
export default ServicesDescriptions;
